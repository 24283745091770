<template>
<div>

    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 4%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting"></waiting-modal>
    <v-row class="align-center d-flex h-0 pb-20">
        <v-col lg="4" sm="6" class="mx-auto" style="padding: 0px 0px 0px 0px">
            <v-card class="" style="">
                <v-card-title class="text-primary" style="
              justify-content: center;
              font-weight: 600 !important;
              padding-bottom: 20px;
              font-size: 24px;
            ">
                    <span> {{form_title}}
                    </span>
                </v-card-title>

                <v-card-subtitle style="
              height: 23px;
              text-align: center !important;
              background-color: #ac55991f !important;
              color: #a84192;
            ">
                    <span>
                        <li>{{form_sub_title}}</li>
                    </span>
                </v-card-subtitle>

                <v-card-text style="border-radius: 7px; padding-top: 25px">

                    <v-row dense align="center">
                        <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
                            <!-- <v-text-field :append-icon="show1 ? 'visibility' : 'visibility_off'" :type="show1 ? 'number' : 'password'" counter="6" v-model="passcode" :rules="passcode_rule" label="กำหนดเลข Passcode 6 หลัก" dense outlined required @click:append="show1 = !show1"></v-text-field> 
                            <v-text-field :append-icon="show1 ? 'visibility' : 'visibility_off'" :type="show1 ? 'number' : 'password'" counter="6" v-model="passcode_confirm" :rules="passcode_confirm_rule" label="ยืนยัน" dense outlined required></v-text-field>  -->

                            <v-text-field type="tel" counter="6" v-model="passcode" :rules="passcode_rule" label="กำหนดเลข Passcode 6 หลัก" dense outlined required ></v-text-field>
                            <v-text-field type="tel" counter="6" v-model="passcode_confirm" :rules="passcode_confirm_rule" label="ยืนยัน Passcode" dense outlined required></v-text-field>


                        </v-form>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
        <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
            <v-icon small>west</v-icon>
        </v-btn>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
            ยืนยันข้อมูล
        </v-btn>
    </v-row>

    <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
        <v-row class="align-center" justify="center">
            <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                ยืนยันข้อมูล
            </v-btn>
        </v-row>
    </v-footer>
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "register-select-type",
    components: {
        WaitingModal,
    },
    data() {
        return {
            active_waiting: false,
            title: '',
            status: '',
            form_title: null,
            form_sub_title: null,
            valid: null,
            show1: false,
            passcode: null,
            passcode_confirm: null,
            register_form: {},
            passcode_rule: [
                v => !!v || "โปรดกรอกข้อมูล",
                v => (v && v.length == 6) || "pass code 6 หลัก",
                // v => isNaN(!v) || v
            ],
            passcode_confirm_rule: [
                v => !!v || "โปรดกรอกข้อมูล",
                v => (v == this.passcode) || "pass code ไม่ตรงกัน"
            ],
        };
    },
    mounted() {
        this.register_form = this.$store.state.register_form
        //console.log(this.register_form)
        this.getFromTitle()
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    },
    methods: {
        validKeyCode(code) {
        const isLetter = code >= 65 && code <= 90;
        const isLowercaseLetter = code >= 97 && code <= 122;
        const isNumber = code >= 48 && code <= 57;
        const isDelete = code === 0;
        return isNumber || isLetter || isLowercaseLetter || isDelete;
        },
        save(date) {
            this.$refs.menu.save(date)
        },
        validate() {
            const res = this.$refs.form.validate();
            if (res) {
                this.register_form.passcode = this.passcode_confirm
                this.submitRegister()
                //console.log(this.register_form)
            }

        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();

        },
        getFromTitle() {
            if (this.register_form.user_type === 'user_provider') {
                this.form_title = 'ลงทะเบียนโรงพยาบาล/คลินิก'
                this.form_sub_title = 'Healthcare provider'
            }
            if (this.register_form.user_type === 'user_osm') {
                this.form_title = 'ลงทะเบียน อสม.'
                this.form_sub_title = 'Healthcare provider'
            }
            if (this.register_form.user_type === 'user_patient') {
                this.form_title = 'ลงทะเบียนผู้ใช้งาน/ผู้ป่วย'
                this.form_sub_title = 'Caregiver/Patient'
            }
        },
        async submitRegister() {
            //console.log(this.register_form)
            if (this.register_form.user_type == 'user_osm') {
                //call api check osm
                this.active_waiting = true
                this.title = 'กำลังลงทะเบียน',
                    this.status = 'loading'

                await Vue.axios.post(APIURL + '/register_user_osm', this.register_form, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.res = response.data
                    //console.log(response.data)

                    if (this.res.detail.user_id) {
                        this.title = 'เพิ่มข้อมูลสำเร็จ';
                        this.status = 'done'

                        this.$store.commit('setLoginDetail', {
                            user_id: this.res.detail.user_id,
                            user_type: this.res.detail.user_type,
                            user_token: this.res.detail.user_token,

                        });
                        setTimeout(() => {
                            this.active_waiting = false
                            this.$router.push('/login')
                        }, 3000)
                    } else {

                        if (this.res.detail == 'lineid_already_registered') {
                            this.title = 'บัญชีไลน์นี้ลงทะเบียนไปแล้ว';
                            this.status = 'fail'
                            this.$store.commit('setLoginDetail', {
                                user_id: this.res.detail.user_id,
                                user_type: this.res.detail.user_type,
                                user_token: this.res.detail.user_token,
                            });
                            setTimeout(() => {
                                this.$router.push('/home')
                            }, 3000)
                        }
                    }
                })
            }

            if (this.register_form.user_type == 'user_provider') {
                //call api check osm
                this.active_waiting = true
                this.title = 'กำลังลงทะเบียน',
                    this.status = 'loading'

                await Vue.axios.post(APIURL + '/register_user_provider', this.register_form, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.res = response.data
                    //console.log(response.data)

                    if (this.res.detail.user_id) {
                        this.title = 'เพิ่มข้อมูลสำเร็จ';
                        this.status = 'done'

                        this.$store.commit('setLoginDetail', {
                            user_id: this.res.detail.user_id,
                            user_type: this.res.detail.user_type,
                            user_token: this.res.detail.user_token,

                        });
                        setTimeout(() => {
                            this.active_waiting = false
                            this.$router.push('/login')
                        }, 3000)
                    } else {

                        if (this.res.detail == 'lineid_already_registered') {
                            this.title = 'บัญชีไลน์นี้ลงทะเบียนไปแล้ว';
                            this.status = 'fail'
                            this.$store.commit('setLoginDetail', {
                                user_id: this.res.detail.user_id,
                                user_type: this.res.detail.user_type,
                                user_token: this.res.detail.user_token,
                            });
                            setTimeout(() => {
                                localStorage.clear();
                                this.$router.push('/home')
                            }, 3000)

                        }

                    }
                })

            }

        }
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #FFFFFF;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #A84192;
}

.v-main__wrap {

    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}
</style>
